body {
  font-family: "Lato", sans-serif;
  font-weight: 900;
}

a {
  color: rgba(0, 0, 0, 0.5);
  transition: 0.2s;
  text-decoration: none;
}

a:hover {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.2s;
}

.aboutContact {
  color: black;
}
.aboutContact:hover {
  color: black;
}

.aboutContactDiv {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 40px 1fr;
  max-width: 350px;
  transition: opacity 0.2s;
}

.aboutContactDiv:hover {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.contactSection {
  margin-top: 30px;
}

li {
  list-style-type: none;
  display: inline-block;
  cursor: pointer;
}

.homeIcon {
  width: 40px;
  transform: scaleX(-1);
  fill: rgba(0, 0, 0, 0.4);
  transition: 0.2s;
  z-index: 100;
}

.homeIcon:hover {
  fill: rgba(0, 0, 0, 1);
  transition: 0.2s;
}

.navLink {
  color: rgba(0, 0, 0, 0.4);
  transition: 0.2s;
  text-decoration: none;
}

.navLink:hover {
  color: rgba(0, 0, 0, 1);
  transition: 0.2s;
}

.codePenIcon,
.emailIcon,
.gitHubIcon,
.linkedInIcon {
  position: relative;
  right: 10px;
  width: 30px;
  margin: 15px;
  fill: rgba(0, 0, 0, 0.4);
  transition: 0.2s;
  z-index: 100;
  cursor: pointer;
}

.codePenIcon:hover,
.emailIcon:hover,
.gitHubIcon:hover,
.linkedInIcon:hover {
  fill: rgba(0, 0, 0, 1);
  transition: 0.2s;
}

.linkedInIcon {
  transform: translateY(3.5px);
}

.footerCopyright {
  position: absolute;
  margin: 6px 0 0 25px;
  bottom: 25px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
}

.footerLinks {
  width: 100vw;
  height: 100%;
  text-align: right;
}

.mainHeader {
  position: absolute;
  top: 25vh;
  left: 15vw;
}

.mainName {
  font-size: 3em;
}

.mainTitle {
  font-size: 2.05em;
  color: rgba(0, 0, 0, 0.4);
}

.gramophone {
  position: absolute;
  top: 15vh;
  right: 15vw;
  width: 35vw;
  filter: grayscale(100%);
}

.aboutGrid {
  margin: 100px auto;
  width: 70vw;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 30vw;
}

.aboutParagraphs {
  position: relative;
  font-size: 1.2em;
  line-height: 1.4em;
  letter-spacing: 0em;
}

.photoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.vignette {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: radial-gradient(rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.5));
}

.portrait {
  position: absolute;
  width: 300px;
  filter: grayscale(100%);
  z-index: 1;
}

.projectsContainer {
  margin: 130px 10vw 70px 10vw;
}

.projectGrid {
  margin: 80px auto 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.screenCapCell {
  text-align: center;
  height: 300px;
  max-height: 263px;
}
.screencapContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.descriptionCell {
  position: relative;
  padding: 10px 10px 10px 20px;
}
.projectNumber {
  font-weight: 400;
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.4);
}
.projectName2 {
  color: black;
  font-size: 2em;
}
.projectTitleLink {
  color: black;
}
.projectTitleLink:hover {
  color: rgba(255, 255, 255, 0.7);
}
.projectDescription2 {
  font-size: 1.5em;
  color: rgba(0, 0, 0, 0.4);
}
.projectName1,
.projectDescription1 {
  display: none;
}
.projectSummary {
  margin: 5px 0 0 0;
  font-weight: 700;
  font-size: 1.1em;
  line-height: 1.2em;
  letter-spacing: 0em;
}
.projectTechIcon {
  width: 25px;
  margin: 10px 5px 10px 5px;
}
.projectTechIconWide {
  width: 70px;
  margin: 10px 5px;
}

.projectLinks {
  position: absolute;
  bottom: -5px;
}
.projectLink {
  display: inline-block;
  margin: 5px 15px 5px 5px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}
.projectLink:hover {
  background-color: black;
  transition: background-color 0.2s;
}
.projectSeperator {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}

@media only screen and (max-width: 1090px) {
  .mainHeader {
    width: 100%;
    left: 0;
    top: 12vh;
    text-align: center;
  }
  .gramophone {
    position: absolute;
    top: auto;
    bottom: 12vh;
    right: 50%;
    width: 40vw;
    transform: translateX(20vw);
    filter: grayscale(100%);
  }

  .projectSummary {
    margin: 5px 0 0 0;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.2em;
    letter-spacing: 0em;
  }
  .projectTechIcon {
    width: 20px;
    margin: 10px 5px 10px 5px;
  }
  .projectTechIconWide {
    width: 55px;
    margin: 10px 5px;
  }
}

@media only screen and (max-width: 900px) {
  .aboutGrid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .aboutParagraphs {
    grid-row-start: 2;
    width: 100%;
  }
  .photoContainer {
    text-align: center;
  }
  .portrait {
    position: static;
    grid-row-start: 1;
    max-width: 40vw;
  }
  .projectsContainer {
    margin: 130px 2vw 70px 2vw;
  }
}

@media only screen and (max-width: 750px) {
  .footerCopyright {
    margin: 5px;
    bottom: 0;
    font-size: 0.8em;
  }
  .footerLinks {
    margin: 0 auto;
    width: 90vw;
    transform: translateY(-5px);
    display: flex;
    justify-content: space-between;
  }
  .navHomeLink {
    display: none;
  }

  .mainHeader {
    width: 100%;
    left: 0;
    top: 20vh;
    text-align: center;
  }
  .mainName {
    font-size: 2em;
  }
  .mainTitle {
    font-size: 1.38em;
    color: rgba(0, 0, 0, 0.4);
  }
  .gramophone {
    top: 35vh;
    right: 50%;
    width: 300px;
    transform: translateX(150px);
  }

  .projectsContainer {
    margin: 100px 10vw 70px 10vw;
  }
  .projectGrid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
  .screenCapCell {
    text-align: center;
    height: 300px;
    overflow: hidden;
  }
  .screencapContainer {
    position: relative;
    margin: 0 auto;
    width: 80%;
    height: 200px;
    overflow: hidden;
  }
  .projectName2,
  .projectDescription2 {
    display: none;
  }
  .projectName1 {
    display: block;
    text-align: left;
    font-size: 2em;
  }

  .projectDescription1 {
    display: block;
    text-align: left;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
  }
  .descriptionCell {
    padding: 0;
    height: 175px;
  }
  .projectSummary {
    margin: 5px 0 0 0;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.2em;
    letter-spacing: 0em;
  }
  .projectTechIcon {
    width: 18px;
    margin: 10px 5px 10px 5px;
  }
  .projectTechIconWide {
    width: 45px;
    margin: 10px 5px;
  }
  .linkedInIcon {
    transform: translateY(-3.5px);
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: all 500ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: all 500ms ease-out;
}
